import React, { useState } from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { UserAvatar } from '@/components/layout/UserMenu';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/components/ui/Toast';
import { User, Camera, ImagePlus, Shield } from 'lucide-react';

export type SettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Tab = 'profile' | 'clan';

const SettingsModal: React.FC<SettingsModalProps> = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState<Tab>('profile');
  const { user, updateUser } = useAuth();
  const { showToast } = useToast();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const bannerInputRef = React.useRef<HTMLInputElement>(null);
  const [bio, setBio] = useState(user?.bio || '');

  const tabs = [
    { id: 'profile', label: 'Profile', icon: User },
    { id: 'clan', label: 'Clan', icon: Shield }
  ] as const;

  const handleBioChange = async (newBio: string) => {
    try {
      const response = await fetch('https://lumerra.online/api/user/update-bio.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bio: newBio }),
      });

      const data = await response.json();
      if (data.success) {
        showToast('Bio updated successfully', 'success');
        updateUser({ bio: newBio });
      }
    } catch (error) {
      showToast('Failed to update bio', 'error');
    }
  };

  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      showToast('Image must be less than 2MB', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      showToast('Uploading avatar...', 'info');
      const response = await fetch('https://lumerra.online/api/user/update-avatar.php', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        showToast('Avatar updated successfully', 'success');
        updateUser({ avatar: data.data.avatar_url });
      }
    } catch (error) {
      showToast('Failed to upload avatar', 'error');
    }
  };

  const handleBannerUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      showToast('Image must be less than 2MB', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('banner', file);

    try {
      showToast('Uploading banner...', 'info');
      const response = await fetch('https://lumerra.online/api/user/update-banner.php', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();
      if (data.success) {
        showToast('Banner updated successfully', 'success');
        updateUser({ banner: data.data.banner_url });
      }
    } catch (error) {
      showToast('Failed to upload banner', 'error');
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-w-2xl bg-gray-900 p-0 border-gray-800">
        <div className="flex h-[600px]">
          {/* Sidebar */}
          <div className="w-48 border-r border-white/10 p-4 space-y-2">
            {tabs.map(({ id, label, icon: Icon }) => (
              <button
                key={id}
                onClick={() => setActiveTab(id as Tab)}
                className={`w-full flex items-center gap-3 px-4 py-2 rounded-lg text-sm
                  ${activeTab === id 
                    ? 'bg-blue-500/10 text-blue-400' 
                    : 'text-gray-400 hover:bg-white/5 hover:text-white'
                  } transition-colors`}
              >
                <Icon className="w-4 h-4" />
                {label}
              </button>
            ))}
          </div>

          {/* Content */}
          <div className="flex-1 p-6">
            {activeTab === 'profile' && (
              <div className="space-y-6">
                {/* Banner Image */}
                <div className="relative w-full h-32 bg-gray-800 rounded-lg overflow-hidden">
                  {user?.banner && (
                    <img
                      src={`https://lumerra.online${user.banner}`}
                      alt="Profile banner"
                      className="w-full h-full object-cover"
                    />
                  )}
                  <button
                    onClick={() => bannerInputRef.current?.click()}
                    className="absolute inset-0 flex items-center justify-center bg-black/50 
                      opacity-0 hover:opacity-100 transition-opacity"
                  >
                    <ImagePlus className="w-6 h-6 text-white" />
                  </button>
                  <input
                    type="file"
                    ref={bannerInputRef}
                    className="hidden"
                    accept="image/*"
                    onChange={handleBannerUpload}
                  />
                </div>

                <div className="flex items-center gap-6">
                  <div className="relative group">
                    <UserAvatar
                      src={user?.avatar}
                      username={user?.username || ''}
                      size="lg"
                    />
                    <button
                      onClick={() => fileInputRef.current?.click()}
                      className="absolute inset-0 flex items-center justify-center bg-black/50 
                        opacity-0 group-hover:opacity-100 transition-opacity rounded-full"
                    >
                      <Camera className="w-5 h-5 text-white" />
                    </button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      accept="image/*"
                      onChange={handleAvatarUpload}
                    />
                  </div>
                  <div>
                    <h2 className="text-xl font-bold text-white">{user?.username}</h2>
                    <p className="text-gray-400 text-sm">Member since {user?.creation_date}</p>
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-4">Account Information</h3>
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <label className="text-sm text-gray-400">Username</label>
                        <input
                          type="text"
                          value={user?.username || ''}
                          disabled
                          className="w-full px-3 py-2 bg-black/20 border border-white/10 rounded-lg
                            text-white disabled:opacity-50"
                        />
                      </div>
                      <div className="space-y-2">
                        <label className="text-sm text-gray-400">Email</label>
                        <input
                          type="email"
                          value={user?.email || ''}
                          disabled
                          className="w-full px-3 py-2 bg-black/20 border border-white/10 rounded-lg
                            text-white disabled:opacity-50"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">
                      Bio
                    </label>
                    <textarea
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                      onBlur={() => handleBioChange(bio)}
                      placeholder="Write something about yourself..."
                      className="w-full px-3 py-2 bg-black/20 border border-white/10 rounded-lg
                        text-white placeholder-gray-500 min-h-[100px]"
                      maxLength={500}
                    />
                    <p className="text-xs text-gray-400 mt-1">
                      {500 - bio.length} characters remaining
                    </p>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'clan' && (
              <div>
                <div className="text-center py-8">
                  <Shield className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                  <h3 className="text-lg font-semibold text-white mb-2">No Clan</h3>
                  <p className="text-gray-400 mb-4">You are not a member of any clan</p>
                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg
                    hover:bg-blue-600 transition-colors">
                    Create Clan
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;