import React from 'react';
import { Castle, Award, Clock } from 'lucide-react';
import type { ServerStats, CastleInfo } from '../../types/stats';
import { formatNumber } from '../../utils/formatters';

interface QuickStatsProps {
  stats: ServerStats;
  castles: CastleInfo[];
  topScore: number;
}

export const QuickStats: React.FC<QuickStatsProps> = ({ stats, castles, topScore }) => {
  const statItems = [
    {
      label: 'Castle Control',
      value: castles.length.toString(),
      icon: Castle,
      color: 'amber',
      subtitle: 'Contested Territories'
    },
    {
      label: 'Top Gear Score',
      value: formatNumber(topScore),
      icon: Award,
      color: 'purple',
      subtitle: 'Season Record'
    },
    {
      label: 'Server Uptime',
      value: stats.serverUptime,
      icon: Clock,
      color: 'green',
      subtitle: 'Since Last Restart'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {statItems.map((stat, index) => (
        <div 
          key={index}
          className={`bg-gradient-to-br from-${stat.color}-500/10 via-${stat.color}-500/5 to-transparent
            border border-${stat.color}-500/20 rounded-lg p-4 hover:border-${stat.color}-500/40 
            transition-all duration-300 group`}
        >
          <div className="flex items-center gap-3">
            <div className={`p-2 rounded-lg bg-${stat.color}-500/10 text-${stat.color}-400
              group-hover:scale-110 transition-transform duration-300`}>
              <stat.icon className="h-5 w-5" />
            </div>
            <div>
              <div className={`text-${stat.color}-400 text-sm font-medium`}>
                {stat.label}
              </div>
              <div className="text-white text-xl font-bold mt-0.5">
                {stat.value}
              </div>
              <div className="text-gray-400 text-xs mt-1">
                {stat.subtitle}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};