import React, { useState } from 'react';
import { Send, ChevronDown, ChevronUp } from 'lucide-react';
import { UserAvatar } from '@/components/layout/UserMenu';
import type { ChatProps } from './LiveChat';
import { formatTimeAgo } from '@/utils/formatters';

export const DesktopChat: React.FC<ChatProps> = ({
  messages,
  onSendMessage,
  isLoading,
  isMinimized,
  setIsMinimized,
  chatContainerRef,
  messagesEndRef,
  onScroll,
  setSelectedUser
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue.trim()) {
      onSendMessage(inputValue.trim());
      setInputValue('');
    }
  };

  const handleUserClick = (username: string) => {
    fetch(`https://lumerra.online/api/user/profile.php?username=${encodeURIComponent(username)}`, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          setSelectedUser(data.data);
        }
      })
      .catch(console.error);
  };

  if (isMinimized) {
    return (
      <button
        onClick={() => setIsMinimized(false)}
        className="bg-gray-900 rounded-lg p-4 
          border border-white/10 text-white flex items-center gap-2"
      >
        <ChevronUp className="w-4 h-4" />
        Open Chat ({messages.length})
      </button>
    );
  }

  return (
    <div className="w-80 bg-gray-900 rounded-lg shadow-lg border border-white/10">
      <div className="flex items-center justify-between p-3 border-b border-white/10">
        <h3 className="text-white font-medium">Live Chat</h3>
        <button
          onClick={() => setIsMinimized(true)}
          className="text-gray-400 hover:text-white transition-colors"
        >
          <ChevronDown className="w-4 h-4" />
        </button>
      </div>

      <div 
        ref={chatContainerRef}
        className="h-96 overflow-y-auto p-4 space-y-4"
        onScroll={onScroll}
      >
        {messages.map((msg, index) => (
          <div key={`${msg.id}-${index}`} className="flex items-start gap-3">
            <button 
              onClick={() => handleUserClick(msg.sender)}
              className={`flex items-start ${msg.isFriend ? 'ring-2 ring-green-500 rounded-full' : ''}`}
            >
              <UserAvatar src={msg.avatar} username={msg.sender} size="sm" />
            </button>
            <div>
              <div className="flex items-center gap-2">
                <button 
                  onClick={() => handleUserClick(msg.sender)}
                  className={`text-sm font-medium hover:underline ${
                    msg.isFriend ? 'text-green-400' : 'text-blue-400'
                  }`}
                >
                  {msg.sender}
                </button>
                <span className="text-xs text-gray-500">
                  {formatTimeAgo(msg.created_at)}
                </span>
              </div>
              <p className="text-white text-sm mt-1">{msg.content}</p>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="p-3 border-t border-white/10">
        <div className="flex gap-2">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type a message..."
            className="flex-grow bg-black/20 rounded-lg px-3 py-2 text-white 
              text-sm border border-white/10 focus:outline-none focus:border-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !inputValue.trim()}
            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 
              transition-colors disabled:opacity-50"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default DesktopChat;