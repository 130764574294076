// src/mock/mockData.ts

import type { 
  ServerStats, 
  TimeOnlinePlayer, 
  GearScorePlayer, 
  CastleInfo, 
  PvPPlayer, 
  PvEPlayer, 
  NewsItem 
} from '../types/stats';

export const mockData: {
  serverStats: ServerStats;
  timeOnlinePlayers: TimeOnlinePlayer[];
  gearScorePlayers: GearScorePlayer[];
  castles: CastleInfo[];
  pvpPlayers: PvPPlayer[];
  pvePlayers: PvEPlayer[];
  newsItems: NewsItem[];
} = {
  serverStats: {
    totalOnline: 4304,
    pvpMode: 1261,
    pveMode: 2168,
    inTown: 875,
    serverUptime: "127 days",
    lastRestart: "2024-04-01",
    maxCapacity: 5000,
    serverRegion: "US East",
    status: 'online',
    nextMaintenance: "2024-04-15 04:00 UTC"
  },

  timeOnlinePlayers: [
    { 
      rank: 1, 
      name: "Dedicated_Player", 
      hours: 2847, 
      level: 85,
      class: "Warrior",
      guildName: "Immortals",
      achievementPoints: 15420
    },
    { 
      rank: 2, 
      name: "GameMaster", 
      hours: 2456, 
      level: 82,
      class: "Mage",
      guildName: "Arcane Order",
      achievementPoints: 14250
    },
    { 
      rank: 3, 
      name: "NeverSleep", 
      hours: 2234, 
      level: 80,
      class: "Rogue",
      guildName: "Shadow Guild",
      achievementPoints: 13800
    },
    { 
      rank: 4, 
      name: "AlwaysOnline", 
      hours: 2156, 
      level: 79,
      class: "Archer",
      guildName: "Rangers",
      achievementPoints: 12950
    },
    { 
      rank: 5, 
      name: "24_7_Gaming", 
      hours: 2089, 
      level: 78,
      class: "Paladin",
      guildName: "Holy Knights",
      achievementPoints: 12400
    }
  ],

  gearScorePlayers: [
    { 
      rank: 1, 
      name: "Ultimate_Gear", 
      score: 9850, 
      class: "Warrior",
      guildName: "Immortals",
      weaponScore: 3250,
      armorScore: 4100,
      accessoryScore: 2500
    },
    { 
      rank: 2, 
      name: "Epic_Hunter", 
      score: 9720, 
      class: "Archer",
      guildName: "Rangers",
      weaponScore: 3180,
      armorScore: 4050,
      accessoryScore: 2490
    },
    { 
      rank: 3, 
      name: "Legend_Equipment", 
      score: 9680, 
      class: "Mage",
      guildName: "Arcane Order",
      weaponScore: 3150,
      armorScore: 4020,
      accessoryScore: 2510
    },
    { 
      rank: 4, 
      name: "Best_InSlot", 
      score: 9590, 
      class: "Paladin",
      guildName: "Holy Knights",
      weaponScore: 3100,
      armorScore: 4000,
      accessoryScore: 2490
    },
    { 
      rank: 5, 
      name: "Mythic_Wielder", 
      score: 9520, 
      class: "Rogue",
      guildName: "Shadow Guild",
      weaponScore: 3080,
      armorScore: 3950,
      accessoryScore: 2490
    }
  ],

  castles: [
    { 
      castle: "Northern Stronghold", 
      guild: "Immortals", 
      leader: "Emperor_King", 
      members: 95,
      controlDuration: "15 days",
      nextSiege: "2024-04-15 20:00 UTC",
      resources: 85,
      defenseRating: 9500
    },
    { 
      castle: "Southern Citadel", 
      guild: "Dark Knights", 
      leader: "Shadow_Lord", 
      members: 88,
      controlDuration: "8 days",
      resources: 65,
      defenseRating: 8800
    },
    { 
      castle: "Eastern Keep", 
      guild: "Dragon Slayers", 
      leader: "Dragon_Master", 
      members: 92,
      controlDuration: "12 days",
      nextSiege: "2024-04-16 20:00 UTC",
      resources: 75,
      defenseRating: 9200
    },
    { 
      castle: "Western Fortress", 
      guild: "Phoenix Rise", 
      leader: "Flame_King", 
      members: 86,
      controlDuration: "5 days",
      resources: 45,
      defenseRating: 8500
    }
  ],

  pvpPlayers: [
    { 
      rank: 1, 
      name: "Shadow_Slayer", 
      kills: 7596, 
      deaths: 245, 
      className: "Warrior",
      killStreak: 42,
      guildName: "Immortals",
      ranking: 2500
    },
    { 
      rank: 2, 
      name: "MageKing", 
      kills: 6958, 
      deaths: 312, 
      className: "Mage",
      killStreak: 35,
      guildName: "Arcane Order",
      ranking: 2450
    },
    { 
      rank: 3, 
      name: "StealthMaster", 
      kills: 6129, 
      deaths: 286, 
      className: "Rogue",
      killStreak: 38,
      guildName: "Shadow Guild",
      ranking: 2400
    },
    { 
      rank: 4, 
      name: "DragonHunter", 
      kills: 5865, 
      deaths: 325, 
      className: "Archer",
      killStreak: 31,
      guildName: "Rangers",
      ranking: 2350
    },
    { 
      rank: 5, 
      name: "LightBringer", 
      kills: 5532, 
      deaths: 294, 
      className: "Paladin",
      killStreak: 29,
      guildName: "Holy Knights",
      ranking: 2300
    }
  ],

  pvePlayers: [
    { 
      rank: 1, 
      name: "DungeonMaster", 
      score: 85962, 
      className: "Tank",
      guildName: "Immortals",
      bossesDefeated: 1250,
      highestDungeon: "Mythic+ Dragon's Lair (Level 25)"
    },
    { 
      rank: 2, 
      name: "BossSlayer", 
      score: 79584, 
      className: "DPS",
      guildName: "Dragon Slayers",
      bossesDefeated: 1180,
      highestDungeon: "Mythic+ Demon's Gate (Level 24)"
    },
    { 
      rank: 3, 
      name: "HealerPro", 
      score: 75296, 
      className: "Healer",
      guildName: "Holy Knights",
      bossesDefeated: 1120,
      highestDungeon: "Mythic+ Crystal Caverns (Level 23)"
    },
    { 
      rank: 4, 
      name: "RaidLeader", 
      score: 72655, 
      className: "Support",
      guildName: "Phoenix Rise",
      bossesDefeated: 1050,
      highestDungeon: "Mythic+ Ancient Temple (Level 22)"
    },
    { 
      rank: 5, 
      name: "EliteClearer", 
      score: 69324, 
      className: "Tank",
      guildName: "Royal Guards",
      bossesDefeated: 980,
      highestDungeon: "Mythic+ Frost Peak (Level 21)"
    }
  ],

  newsItems: [
    {
      id: 1,
      title: "Season 2: Rise of the Dragon Lords Launching Soon!",
      date: "2024-05-01",
      category: "Major Update",
      content: "Prepare for the most epic season yet! New dragon mounts, legendary weapons, and the introduction of the Dragon Lords system. Join us for the launch event with exclusive rewards and double XP weekend.",
      image: "/api/placeholder/800/400",
      author: "CM_DragonHeart",
      tags: ["Season 2", "Update", "Events"],
      readTime: 5,
      likes: 1250,
      comments: 324
    },
    {
      id: 2,
      title: "Balance Changes: Class Overhaul Coming in Patch 2.5",
      date: "2024-04-28",
      category: "Game Updates",
      content: "Major rebalancing for all classes, new talent trees, and skill modifications. Check out the comprehensive patch notes for detailed information about your class changes.",
      image: "/api/placeholder/800/400",
      author: "CM_Balancer",
      tags: ["Patch Notes", "Balance", "Classes"],
      readTime: 8,
      likes: 856,
      comments: 642
    },
    {
      id: 3,
      title: "Weekend Event: Dragon's Blessing",
      date: "2024-04-25",
      category: "Events",
      content: "Enjoy triple drop rates, enhanced crafting success, and special dragon-themed rewards this weekend. Perfect time to gear up for the upcoming season!",
      image: "/api/placeholder/800/400",
      author: "CM_EventMaster",
      tags: ["Event", "Rewards", "Limited Time"],
      readTime: 3,
      likes: 524,
      comments: 128
    }
  ]
};