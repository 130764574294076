import React, { useState, useEffect } from 'react';
import { Signal, Users, Swords, Shield, Home } from 'lucide-react';
import type { ServerStats } from '../../types/stats';

interface ServerStatusProps {
  stats: ServerStats;
}

export const ServerStatus: React.FC<ServerStatusProps> = ({ stats }) => {
  const [activeVisitors, setActiveVisitors] = useState(0);

  useEffect(() => {
    const updateActiveVisitors = async () => {
      try {
        const response = await fetch('/api/active-visitors.php');
        const data = await response.json();
        if (data.success) {
          setActiveVisitors(data.active_visitors);
        }
      } catch (error) {
        console.error('Error fetching active visitors:', error);
      }
    };

    updateActiveVisitors();
    const interval = setInterval(updateActiveVisitors, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Signal className="text-blue-400 h-4 w-4" />
          Server Status
        </h3>
      </div>

      <div className="p-4 space-y-4">
        <div className="bg-gradient-to-r from-blue-500/10 to-blue-500/5 rounded-lg p-3">
          <div className="flex items-center gap-3">
            <Users className="h-5 w-5 text-blue-400" />
            <div>
              <div className="text-gray-400 text-sm">Online Players</div>
              <div className="text-white font-bold">{activeVisitors}</div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2 text-sm">
          <div className="bg-gradient-to-r from-red-500/10 to-transparent rounded-lg p-3">
            <div className="flex items-center gap-2">
              <Swords className="h-4 w-4 text-red-400" />
              <div>
                <div className="text-gray-400">PvP</div>
                <div className="text-red-400 font-bold">0</div>
              </div>
            </div>
          </div>

          <div className="bg-gradient-to-r from-green-500/10 to-transparent rounded-lg p-3">
            <div className="flex items-center gap-2">
              <Shield className="h-4 w-4 text-green-400" />
              <div>
                <div className="text-gray-400">PvE</div>
                <div className="text-green-400 font-bold">0</div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-r from-purple-500/10 to-transparent rounded-lg p-3">
          <div className="flex items-center gap-2">
            <Home className="h-4 w-4 text-purple-400" />
            <div>
              <div className="text-gray-400">In Town</div>
              <div className="text-purple-400 font-bold">0</div>
            </div>
          </div>
        </div>

        <div className="border-t border-white/5 pt-3 mt-3">
          <div className="flex justify-between text-xs">
            <span className="text-gray-400">Server Uptime:</span>
            <span className="text-white">{stats.serverUptime}</span>
          </div>
          <div className="flex justify-between text-xs mt-1">
            <span className="text-gray-400">Last Restart:</span>
            <span className="text-white">{new Date(stats.lastRestart).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
    </div>
  );
};