// src/App.tsx
import { useState } from 'react';
import { Star } from 'lucide-react';
import { Navigation } from './components/layout/Navigation';
import { MobileMenu } from './components/layout/MobileMenu';
import { ServerStatus } from './components/stats/ServerStatus';
import { TimeOnlineRankings } from './components/stats/TimeOnlineRankings';
import { GearScoreRankings } from './components/stats/GearScoreRankings';
import { CastleControl } from './components/stats/CastleControl';
import { PvPRankings } from './components/stats/PvPRankings';
import { PvERankings } from './components/stats/PvERankings';
import { NewsCard } from './components/news/NewsCard';
import { QuickStats } from './components/stats/QuickStats';
import { Footer } from './components/layout/Footer';
import LiveChat from './components/chat/LiveChat';
import { mockData } from './mock/mockData';
import { useAuth } from '@/hooks/useAuth';

const AppContent = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('news');
  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mb-4" />
          <p className="text-gray-400">Loading your experience...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-950 via-indigo-950 to-blue-950">
      <div className="flex flex-col min-h-screen">
        <Navigation 
          activeTab={activeTab} 
          setActiveTab={setActiveTab}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
        />
        
        <MobileMenu 
          isOpen={isMobileMenuOpen}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setIsOpen={setIsMobileMenuOpen}
        />

        <main className="flex-grow">
          <div className="container mx-auto px-4 py-8">
            <div className="flex flex-col lg:flex-row gap-8">
              <div className="w-full lg:w-72 space-y-4">
                <ServerStatus stats={mockData.serverStats} />
                <TimeOnlineRankings players={mockData.timeOnlinePlayers} />
                <GearScoreRankings players={mockData.gearScorePlayers} />
                <CastleControl castles={mockData.castles} />
              </div>

              <div className="flex-grow space-y-8">
                <QuickStats 
                  stats={mockData.serverStats} 
                  castles={mockData.castles} 
                  topScore={mockData.gearScorePlayers[0].score}
                />

                <div className="flex items-center gap-2 pb-2 border-b border-white/10">
                  <Star className="text-yellow-400" />
                  <h2 className="text-2xl font-bold text-white">LATEST NEWS</h2>
                </div>
                
                <div className="grid md:grid-cols-2 gap-6">
                  {mockData.newsItems.map((news) => (
                    <NewsCard key={news.id} news={news} />
                  ))}
                </div>
              </div>

              <div className="w-full lg:w-72 space-y-4">
                <PvPRankings players={mockData.pvpPlayers} />
                <PvERankings players={mockData.pvePlayers} />
              </div>
            </div>
          </div>
        </main>

        <Footer />
        <LiveChat />
      </div>
    </div>
  );
};

export default function App() {
  return <AppContent />;
}