import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/components/ui/Toast';
import { DesktopChat } from './DesktopLiveChat';
import { MobileChat } from './MobileLiveChat';
import UserProfileModal from '@/components/modals/UserProfileModal';

interface ChatMessage {
  id: number;
  sender: string;
  content: string;
  is_guest: boolean;
  created_at: string;
  avatar?: string | null;
}

interface Friend {
  username: string;
  avatar?: string | null;
}

interface UserProfile {
  username: string;
  avatar?: string | null;
  banner?: string | null;
  bio?: string | null;
  creation_date?: string;
  rank?: string;
  location?: string;
  clan?: string;
}

export interface ChatProps {
  messages: (ChatMessage & { isFriend?: boolean })[];
  onSendMessage: (message: string) => void;
  isLoading: boolean;
  isMinimized: boolean;
  setIsMinimized: (value: boolean) => void;
  shouldAutoScroll: boolean;
  onScroll: () => void;
  messagesEndRef: React.RefObject<HTMLDivElement>;
  chatContainerRef: React.RefObject<HTMLDivElement>;
  selectedUser: UserProfile | null;
  setSelectedUser: (user: UserProfile | null) => void;
  isConnected: boolean;
}

const LiveChat: React.FC = () => {
  const [messages, setMessages] = useState<(ChatMessage & { isFriend?: boolean })[]>([]);
  const [isMinimized, setIsMinimized] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [selectedUser, setSelectedUser] = useState<UserProfile | null>(null);
  const [isConnected, setIsConnected] = useState(true);
  const [friends, setFriends] = useState<Friend[]>([]);

  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const chatContainerRef = React.useRef<HTMLDivElement>(null);
  const isFirstLoad = React.useRef(true);
  const previousMessageCount = React.useRef(0);
  const fetchTimeoutRef = React.useRef<NodeJS.Timeout>();

  const { user } = useAuth();
  const { showToast } = useToast();

  const RECONNECT_INTERVAL = 3000;
  const MESSAGE_FETCH_INTERVAL = 3000;

  const fetchFriends = async () => {
    try {
      const response = await fetch('https://lumerra.online/api/friends/get-friends.php', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
        setFriends(data.data.friends);
      }
    } catch (error) {
      console.error('Failed to fetch friends:', error);
    }
  };

  const scrollToBottom = useCallback((force = false) => {
    if (force || shouldAutoScroll) {
      requestAnimationFrame(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }, [shouldAutoScroll]);

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    const { scrollHeight, scrollTop, clientHeight } = chatContainerRef.current;
    const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 50;
    setShouldAutoScroll(isAtBottom);
  }, []);

  const fetchMessages = useCallback(async () => {
    try {
      const response = await fetch('https://lumerra.online/api/chat/chat_handler.php', {
        credentials: 'include'
      });

      if (!response.ok) throw new Error('Failed to fetch messages');

      const data = await response.json();
      if (data.success) {
        const messagesWithFriendStatus = data.messages.map((msg: ChatMessage) => ({
          ...msg,
          isFriend: friends.some(friend => friend.username === msg.sender)
        }));

        const hasNewMessages = messagesWithFriendStatus.length > previousMessageCount.current;
        previousMessageCount.current = messagesWithFriendStatus.length;

        if (isFirstLoad.current || (hasNewMessages && shouldAutoScroll)) {
          setMessages(messagesWithFriendStatus);
          scrollToBottom(isFirstLoad.current);
        } else {
          setMessages(messagesWithFriendStatus);
        }

        isFirstLoad.current = false;
        setIsConnected(true);
        setRetryCount(0);
      }
    } catch (error) {
      setIsConnected(false);
      const nextRetry = Math.min(RECONNECT_INTERVAL * Math.pow(2, retryCount), 30000);
      setRetryCount(prev => prev + 1);
      fetchTimeoutRef.current = setTimeout(fetchMessages, nextRetry);
    }
  }, [shouldAutoScroll, retryCount, scrollToBottom, friends]);

  const handleSendMessage = async (message: string) => {
    if (!message.trim() || isLoading) return;

    setIsLoading(true);
    try {
      const response = await fetch('https://lumerra.online/api/chat/chat_handler.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          content: message,
          sender: user ? user.username : `Guest${Math.floor(Math.random() * 100000)}`,
          isGuest: !user,
        })
      });

      if (!response.ok) throw new Error('Failed to send message');

      const data = await response.json();
      if (data.success) {
        await fetchMessages();
        scrollToBottom(true);
      } else {
        throw new Error(data.message || 'Failed to send message');
      }
    } catch (error) {
      showToast('Failed to send message', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFriends();
  }, []);

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, MESSAGE_FETCH_INTERVAL);
    return () => {
      clearInterval(interval);
      if (fetchTimeoutRef.current) clearTimeout(fetchTimeoutRef.current);
    };
  }, [fetchMessages]);

  useEffect(() => {
    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const chatProps: ChatProps = {
    messages,
    onSendMessage: handleSendMessage,
    isLoading,
    isMinimized,
    setIsMinimized,
    shouldAutoScroll,
    onScroll: handleScroll,
    messagesEndRef,
    chatContainerRef,
    selectedUser,
    setSelectedUser,
    isConnected
  };

  return (
    <>
      <UserProfileModal
        isOpen={selectedUser !== null}
        onClose={() => setSelectedUser(null)}
        userData={selectedUser}
      />
      <div className={`fixed bottom-4 right-4 z-50 transition-all duration-300
        ${isConnected ? 'opacity-100' : 'opacity-75'}`}>
        <div className="md:hidden">
          <MobileChat {...chatProps} />
        </div>
        <div className="hidden md:block">
          <DesktopChat {...chatProps} />
        </div>
      </div>
    </>
  );
};

export default LiveChat;