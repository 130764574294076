import { useState, useRef, useEffect } from 'react';
import { Bell, UserCheck, UserX } from 'lucide-react';
import { formatTimeAgo } from '@/utils/formatters';
import { UserAvatar } from '@/components/layout/UserMenu';
import { useToast } from '@/components/ui/Toast';
import { useClickOutside } from '@/hooks/useClickOutside';

interface Notification {
  id: number;
  type: string;
  is_read: boolean;
  created_at: string;
  sender_username: string;
  sender_avatar: string | null;
}

export const NotificationBell = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const { showToast } = useToast();

  useClickOutside(menuRef, () => setIsOpen(false));

  const fetchNotifications = async () => {
    try {
      const response = await fetch('https://lumerra.online/api/notifications/get-notifications.php', {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
        setNotifications(data.data.notifications);
        setUnreadCount(data.data.unread_count);
      }
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };

  const markAsRead = async (notificationIds?: number[]) => {
    try {
      const response = await fetch('https://lumerra.online/api/notifications/mark-read.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notification_ids: notificationIds }),
      });
      if (response.ok) {
        await fetchNotifications();
      }
    } catch (error) {
      console.error('Failed to mark notifications as read:', error);
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
    if (unreadCount > 0) {
      markAsRead();
    }
  };

  const handleFriendRequest = async (notificationId: number, action: 'accept' | 'decline') => {
    try {
      const response = await fetch('https://lumerra.online/api/friends/handle-request.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notificationId, action }),
      });

      const data = await response.json();
      if (data.success) {
        showToast(
          action === 'accept' ? 'Friend request accepted!' : 'Friend request declined',
          action === 'accept' ? 'success' : 'info'
        );
        fetchNotifications();
      } else {
        throw new Error(data.message || 'Failed to handle friend request');
      }
    } catch (error) {
      showToast('Failed to handle friend request', 'error');
    }
  };

  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(interval);
  }, []);

  const renderNotificationContent = (notification: Notification) => {
    switch (notification.type) {
      case 'friend_request':
        return (
          <div className="flex flex-col gap-2">
            <div className="text-sm text-white">
              {notification.sender_username} sent you a friend request
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handleFriendRequest(notification.id, 'accept')}
                className="flex items-center gap-1 px-3 py-1 bg-green-500/10 text-green-400
                  rounded-lg text-xs hover:bg-green-500/20 transition-colors"
              >
                <UserCheck className="w-3 h-3" />
                Accept
              </button>
              <button
                onClick={() => handleFriendRequest(notification.id, 'decline')}
                className="flex items-center gap-1 px-3 py-1 bg-red-500/10 text-red-400
                  rounded-lg text-xs hover:bg-red-500/20 transition-colors"
              >
                <UserX className="w-3 h-3" />
                Decline
              </button>
            </div>
          </div>
        );
      case 'friend_accept':
        return `${notification.sender_username} accepted your friend request`;
      default:
        return 'New notification';
    }
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={handleOpen}
        className="p-2 text-gray-400 hover:text-white transition-colors"
      >
        <Bell className="h-5 w-5" />
        {unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs
            w-4 h-4 flex items-center justify-center rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <>
          <div className="absolute right-0 mt-2 w-80 bg-gray-900 rounded-lg shadow-xl
            border border-white/10 z-50">
            <div className="p-4 border-b border-white/10">
              <h3 className="text-white font-medium">Notifications</h3>
            </div>

            <div className="max-h-[400px] overflow-y-auto">
              {notifications.length > 0 ? (
                notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`p-4 border-b border-white/5 hover:bg-white/5 transition-colors
                      ${notification.is_read ? 'opacity-75' : ''}`}
                  >
                    <div className="flex items-start gap-3">
                      <UserAvatar
                        src={notification.sender_avatar}
                        username={notification.sender_username}
                        size="sm"
                      />
                      <div className="flex-1">
                        {renderNotificationContent(notification)}
                        <span className="block text-xs text-gray-400 mt-1">
                          {formatTimeAgo(notification.created_at)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="p-4 text-center text-gray-400 text-sm">
                  No notifications
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NotificationBell;