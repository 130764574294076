import React from 'react';
import { Castle, Crown, Users, Clock, Shield, ChevronRight } from 'lucide-react';
import type { CastleInfo } from '../../types/stats';
import { truncateText } from '../../utils/formatters';

interface CastleControlProps {
  castles: CastleInfo[];
}

export const CastleControl: React.FC<CastleControlProps> = ({ castles }) => {
  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Castle className="text-amber-400 h-4 w-4" />
          Castle Control
        </h3>
      </div>

      <div className="divide-y divide-white/5">
        {castles.map((castle, index) => (
          <div key={index} className="relative group">
            <div className="p-4 hover:bg-white/5 transition-colors">
              <div className="flex items-center gap-2 mb-2">
                <span className="text-amber-400 font-semibold text-sm">
                  {castle.castle}
                </span>
                {castle.nextSiege && (
                  <span className="text-xs bg-red-500/20 text-red-400 px-2 py-0.5 rounded-full ml-auto">
                    Siege Soon
                  </span>
                )}
              </div>

              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Crown className="h-3 w-3 text-yellow-400" />
                  <div className="flex items-center gap-1 min-w-0">
                    <span className="text-white text-sm truncate" title={castle.guild}>
                      {truncateText(castle.guild, 15)}
                    </span>
                  </div>
                </div>

                <div className="flex items-center gap-2 text-xs">
                  <ChevronRight className="h-3 w-3 text-gray-400" />
                  <div className="flex items-center gap-1">
                    <span className="text-gray-400">Leader:</span>
                    <span className="text-white truncate" title={castle.leader}>
                      {truncateText(castle.leader, 12)}
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-2 text-xs">
                  <div className="flex items-center gap-1">
                    <Users className="h-3 w-3 text-blue-400" />
                    <span className="text-gray-400">Members:</span>
                    <span className="text-white">{castle.members}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Shield className="h-3 w-3 text-green-400" />
                    <span className="text-gray-400">Defense:</span>
                    <span className="text-white">{castle.defenseRating}</span>
                  </div>
                </div>

                <div className="flex items-center gap-1 text-xs">
                  <Clock className="h-3 w-3 text-purple-400" />
                  <span className="text-gray-400">Control Time:</span>
                  <span className="text-white">{castle.controlDuration}</span>
                </div>
              </div>
            </div>

            {/* Resources Progress Bar */}
            <div className="px-4 pb-4">
              <div className="text-xs text-gray-400 mb-1 flex justify-between">
                <span>Resources</span>
                <span className="text-amber-400">{castle.resources}%</span>
              </div>
              <div className="h-1 bg-white/5 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-amber-500 to-amber-400 transition-all duration-300"
                  style={{ width: `${castle.resources}%` }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};