import React from 'react';
import { ArrowRight, Calendar, Tag } from 'lucide-react';
import type { NewsItem } from '@/types/news';
import { formatDate } from '@/utils/formatters';

interface NewsCardProps {
  news: NewsItem;
}

export const NewsCard: React.FC<NewsCardProps> = ({ news }) => {
  return (
    <div className="group bg-black/20 backdrop-blur-sm border border-white/10 rounded-lg overflow-hidden 
      transition-all duration-300 hover:border-blue-500/50 hover:shadow-lg hover:shadow-blue-500/10">
      <div className="relative">
        <img 
          src={news.image || "https://placehold.co/800x400"}
          alt={news.title}
          className="w-full h-48 object-cover transition-transform duration-500 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent" />
        
        <div className="absolute bottom-4 left-4 right-4">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-2 text-blue-300 text-sm">
              <Calendar className="h-4 w-4" />
              {formatDate(news.date)}
            </div>
            <div className="flex items-center gap-2 bg-blue-500/20 text-blue-300 text-xs px-3 py-1 rounded-full">
              <Tag className="h-3 w-3" />
              {news.category}
            </div>
          </div>
        </div>
      </div>
      
      <div className="p-6">
        <h3 className="text-xl font-bold text-white mb-2 group-hover:text-blue-400 
          transition-colors line-clamp-2">
          {news.title}
        </h3>
        
        <p className="text-gray-400 line-clamp-2 mb-4 text-sm">
          {news.content}
        </p>
        
        <button className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 
          transition-colors text-sm font-medium group/button">
          Read More
          <ArrowRight className="h-4 w-4 transform group-hover/button:translate-x-1 transition-transform" />
        </button>
      </div>
    </div>
  );
};