// src/components/layout/Navigation.tsx
import React, { useState } from 'react';
import { Search, Menu, Sword } from 'lucide-react';
import { RegisterModal } from '@/components/modals/RegisterModal';
import { LoginModal } from '@/components/modals/LoginModal';
import { UserMenu } from './UserMenu';
import { useToast } from '@/components/ui/Toast';
import { useAuth } from '@/hooks/useAuth';
import FriendList from '@/components/friends/FriendSystem';
import { NotificationBell } from '@/components/notifications/NotificationBell';

interface NavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  setIsMobileMenuOpen: (isOpen: boolean) => void;
}

export const Navigation: React.FC<NavigationProps> = ({ 
  activeTab, 
  setActiveTab, 
  setIsMobileMenuOpen 
}) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { user, isAuthenticated, login } = useAuth();
  const { showToast } = useToast();
  
  const navItems = [
    { id: 'news', label: 'NEWS' },
    { id: 'about', label: 'ABOUT' },
    { id: 'files', label: 'FILES' },
    { id: 'donate', label: 'DONATE' },
    { id: 'forum', label: 'FORUM' }
  ];

  const handleNavClick = (item: string) => {
    setActiveTab(item);
  };

  const handleLoginSuccess = async (credentials: { 
    username: string; 
    password: string; 
    remember: boolean; 
  }) => {
    try {
      const success = await login(credentials.username, credentials.password);
      if (success) {
        setShowLoginModal(false);
        showToast(`Welcome back, ${credentials.username}!`, 'success');
      } else {
        showToast('Login failed. Please check your credentials.', 'error');
      }
    } catch (error) {
      showToast('An error occurred during login.', 'error');
    }
  };

  return (
    <>
      <nav className="bg-black/30 backdrop-blur-sm sticky top-0 z-50 border-b border-white/10">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Left side with logo and navigation */}
            <div className="flex items-center gap-8">
              {/* Logo */}
              <div className="flex items-center gap-2">
                <Sword className="h-8 w-8 text-blue-400" />
                <div>
                  <div className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-purple-400 
                    bg-clip-text text-transparent">
                    Lumerra Online
                  </div>
                  <div className="text-xs text-gray-400">Adventure Awaits</div>
                </div>
              </div>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center gap-6">
                {navItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleNavClick(item.id)}
                    className={`text-sm font-medium transition-all px-3 py-1 rounded ${
                      activeTab === item.id
                        ? 'text-blue-400 bg-blue-400/10'
                        : 'text-gray-400 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>

            {/* Right side with user controls */}
            <div className="flex items-center gap-4">
              {/* Authenticated User Controls */}
              {isAuthenticated && (
                <>
                  <NotificationBell />
                  <FriendList />
                </>
              )}

              {/* Search Button */}
              <button className="p-2 text-gray-400 hover:text-white transition-colors">
                <Search className="h-5 w-5" />
              </button>

              {/* User Menu or Auth Buttons */}
              <div className="hidden md:flex items-center gap-2">
                {isAuthenticated && user ? (
                  <div className="flex items-center gap-4">
                    <UserMenu />
                    <button 
                      className="px-4 py-1.5 bg-blue-500 text-white rounded-lg
                        hover:bg-blue-600 transition-colors"
                    >
                      PLAY NOW
                    </button>
                  </div>
                ) : (
                  <>
                    <button 
                      onClick={() => setShowLoginModal(true)}
                      className="px-4 py-1.5 text-gray-300 hover:text-white transition-colors"
                    >
                      LOGIN
                    </button>
                    <button 
                      onClick={() => setShowRegisterModal(true)}
                      className="px-4 py-1.5 bg-blue-500/10 text-blue-400 rounded-lg
                        hover:bg-blue-500/20 transition-colors"
                    >
                      REGISTER
                    </button>
                    <button 
                      className="px-4 py-1.5 bg-blue-500 text-white rounded-lg
                        hover:bg-blue-600 transition-colors"
                    >
                      PLAY NOW
                    </button>
                  </>
                )}
              </div>

              {/* Mobile Menu Button */}
              <button 
                className="md:hidden p-2 text-gray-400 hover:text-white transition-colors"
                onClick={() => setIsMobileMenuOpen(true)}
              >
                <Menu className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Modals */}
      <RegisterModal 
        isOpen={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
      />
      
      <LoginModal 
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onSuccess={handleLoginSuccess}
      />
    </>
  );
};

export default Navigation;