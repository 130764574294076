// src/components/layout/UserMenu.tsx
import { useState, useRef } from 'react';
import { Camera, Settings, LogOut } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/components/ui/Toast';
import SettingsModal from '@/components/modals/SettingsModal';
import { useClickOutside } from '@/hooks/useClickOutside';

export const UserAvatar: React.FC<{
  src?: string | null;
  username: string;
  size?: 'sm' | 'md' | 'lg';
}> = ({ src, username, size = 'md' }) => {
  const [imageError, setImageError] = useState(false);
  
  const sizeClasses = {
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
  };

  return (
    <div className={`relative rounded-full overflow-hidden ${sizeClasses[size]}`}>
      {src && !imageError ? (
        <img 
          src={`https://lumerra.online${src}`}
          alt={`${username}'s avatar`} 
          className="w-full h-full object-cover"
          onError={() => setImageError(true)}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center bg-blue-500/10">
          <span className="text-blue-400 font-medium">
            {username[0].toUpperCase()}
          </span>
        </div>
      )}
    </div>
  );
};

export const UserMenu = () => {
  const { user, updateUser, logout } = useAuth();
  const { showToast } = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuRef, () => setIsOpen(false));

  const handleLogout = async () => {
    if (isLoggingOut) return;
    
    try {
      setIsLoggingOut(true);
      await logout();
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.lumerra.online';
      document.cookie = 'auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=lumerra.online';
      
      showToast('Logged out successfully', 'success');
      setTimeout(() => window.location.reload(), 100);
    } catch (error) {
      showToast('Failed to logout', 'error');
      setIsLoggingOut(false);
    }
  };

  const handleAvatarUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      showToast('Please select a valid image file (JPG, PNG, or GIF)', 'error');
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      showToast('Image must be less than 2MB', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      showToast('Uploading avatar...', 'info');

      const response = await fetch('https://lumerra.online/api/user/update-avatar.php', {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        showToast('Avatar updated successfully', 'success');
        updateUser({ avatar: data.data.avatar_url });
      } else
throw new Error(data.message || 'Failed to update avatar');
    } catch (error) {
      console.error('Avatar upload error:', error);
      showToast(
        error instanceof Error ? error.message : 'Failed to upload avatar',
        'error'
      );
    }

    event.target.value = '';
  };

  if (!user) return null;

  return (
    <div className="relative" ref={menuRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-3 p-2 rounded-lg hover:bg-white/5 transition-colors"
      >
        <UserAvatar 
          src={user.avatar} 
          username={user.username}
          size="md"
        />
        <div className="text-left">
          <div className="text-sm font-medium text-white">
            {user.username}
          </div>
          <div className="text-xs text-gray-400">
            View Profile
          </div>
        </div>
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 py-2 bg-gray-900 rounded-lg shadow-xl 
          border border-white/10 z-50">
          <button
            onClick={() => fileInputRef.current?.click()}
            className="w-full px-4 py-2 text-sm text-gray-300 hover:bg-white/5 
              transition-colors flex items-center gap-2"
          >
            <Camera className="w-4 h-4" />
            Change Avatar
          </button>

          <button
            onClick={() => {
              setShowSettings(true);
              setIsOpen(false);
            }}
            className="w-full px-4 py-2 text-sm text-gray-300 hover:bg-white/5 
              transition-colors flex items-center gap-2"
          >
            <Settings className="w-4 h-4" />
            Settings
          </button>

          <div className="border-t border-white/10 my-1" />

          <button
            onClick={handleLogout}
            disabled={isLoggingOut}
            className="w-full px-4 py-2 text-sm text-red-400 hover:bg-white/5 
              transition-colors flex items-center gap-2 disabled:opacity-50"
          >
            <LogOut className="w-4 h-4" />
            {isLoggingOut ? 'Logging out...' : 'Logout'}
          </button>
        </div>
      )}

      <input
        type="file"
        accept="image/jpeg,image/png,image/gif"
        ref={fileInputRef}
        onChange={handleAvatarUpload}
        className="hidden"
      />

      <SettingsModal 
        isOpen={showSettings}
        onClose={() => setShowSettings(false)}
      />
    </div>
  );
};

export default UserMenu;