import React from 'react';
import { Award, Shield, Sword } from 'lucide-react';
import type { GearScorePlayer } from '../../types/stats';
import { truncateText, formatNumber } from '../../utils/formatters';

interface GearScoreRankingsProps {
  players: GearScorePlayer[];
}

export const GearScoreRankings: React.FC<GearScoreRankingsProps> = ({ players }) => {
  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Award className="text-purple-400 h-4 w-4" />
          Top Gear Score
        </h3>
      </div>

      <div className="p-2">
        {players.map((player) => (
          <div 
            key={player.rank} 
            className="relative"
          >
            <div className="flex items-center justify-between p-2 hover:bg-white/5 rounded 
              transition-colors group cursor-pointer"
            >
              <div className="flex items-center gap-3 min-w-0">
                <span className="text-white/80 font-bold w-4 text-sm">{player.rank}</span>
                <div className="min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="text-white text-sm truncate" title={player.name}>
                      {truncateText(player.name, 12)}
                    </span>
                    {player.guildName && (
                      <span className="text-xs text-gray-400">
                        &lt;{truncateText(player.guildName, 8)}&gt;
                      </span>
                    )}
                  </div>
                  <span className={`text-xs ${getClassColor(player.class)}`}>
                    {player.class}
                  </span>
                </div>
              </div>
              
              <div className="text-right">
                <div className="text-purple-400 text-sm font-bold">
                  {formatNumber(player.score)}
                </div>
              </div>
            </div>

            {/* Detailed Stats Popup */}
            <div className="absolute left-full ml-2 top-0 z-10 w-48 bg-black/90 rounded-lg 
              border border-white/10 p-3 pointer-events-none opacity-0 translate-x-2
              group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-200
              hidden lg:block"
            >
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Sword className="h-3 w-3 text-red-400" />
                  <span className="text-xs text-gray-400">Weapon Score:</span>
                  <span className="text-xs text-white ml-auto">
                    {formatNumber(player.weaponScore)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <Shield className="h-3 w-3 text-blue-400" />
                  <span className="text-xs text-gray-400">Armor Score:</span>
                  <span className="text-xs text-white ml-auto">
                    {formatNumber(player.armorScore)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <Award className="h-3 w-3 text-yellow-400" />
                  <span className="text-xs text-gray-400">Accessory Score:</span>
                  <span className="text-xs text-white ml-auto">
                    {formatNumber(player.accessoryScore)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="p-3 border-t border-white/5">
        <div className="text-center text-xs text-gray-400">
          Updated every 30 minutes
        </div>
      </div>
    </div>
  );
};

const getClassColor = (className: string): string => {
  const colors: Record<string, string> = {
    'Warrior': 'text-orange-400',
    'Mage': 'text-blue-400',
    'Rogue': 'text-purple-400',
    'Archer': 'text-green-400',
    'Paladin': 'text-yellow-400'
  };
  return colors[className] || 'text-gray-400';
};