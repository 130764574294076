import { createContext, useState, useEffect, type ReactNode } from 'react';
import type { User, AuthContextType } from '@/types/user';

export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const login = async (username: string, password: string): Promise<boolean> => {
    try {
      const response = await fetch('https://lumerra.online/api/login/login.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username,
          password
        })
      });
      
      const data = await response.json();
      if (data.success && data.data) {
        // Add immediate auth check after successful login
        await checkAuth();
        // Add small delay to ensure state is updated
        await new Promise(resolve => setTimeout(resolve, 100));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Login error:', error);
      return false;
    }
  };

  const clearAllBrowserData = () => {
    // Clear all cookies for all possible domains
    const domains = ['', '.lumerra.online', 'lumerra.online'];
    const paths = ['/', '/api'];
    
    domains.forEach(domain => {
      paths.forEach(path => {
        document.cookie = `auth_token=;expires=${new Date(0).toUTCString()};path=${path}${domain ? ';domain=' + domain : ''}`;
        document.cookie = `PHPSESSID=;expires=${new Date(0).toUTCString()};path=${path}${domain ? ';domain=' + domain : ''}`;
      });
    });
    
    // Clear storages
    localStorage.clear();
    sessionStorage.clear();
    
    // Clear application state
    setUser(null);
  };

  const logout = async () => {
    try {
      // First, call backend logout
      const response = await fetch('https://lumerra.online/api/auth/logout.php', {
        method: 'POST',
        credentials: 'include',
        cache: 'no-store',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      
      // Clear all browser data regardless of response
      clearAllBrowserData();
      
      // Wait for backend response
      await response.text();
      
      // Use replace to prevent back navigation
      window.location.replace('/');
    } catch (error) {
      console.error('Logout error:', error);
      // Still clear data even if request fails
      clearAllBrowserData();
      window.location.replace('/');
    }
  };

  const checkAuth = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('https://lumerra.online/api/auth/check.php', {
        credentials: 'include',
        cache: 'no-store',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });

      if (!response.ok) {
        setUser(null);
        return;
      }

      const data = await response.json();
      
      if (!data.success) {
        setUser(null);
        return;
      }

      setUser(data.data);
    } catch (error) {
      console.error('Auth check error:', error);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = (data: Partial<User>) => {
    setUser(prev => prev ? { ...prev, ...data } : null);
  };

  useEffect(() => {
    checkAuth();
    const intervalId = setInterval(checkAuth, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(intervalId);
  }, []);

  return (
    <AuthContext.Provider 
      value={{
        user,
        isAuthenticated: !!user,
        isLoading,
        login,
        logout,
        checkAuth,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}