import React from 'react';
import { Swords } from 'lucide-react';
import type { PvPPlayer } from '../../types/stats';
import { truncateText } from '../../utils/formatters';

interface PvPRankingsProps {
  players: PvPPlayer[];
}

export const PvPRankings: React.FC<PvPRankingsProps> = ({ players }) => {
  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Swords className="text-red-400 h-4 w-4" />
          PVP Rankings
        </h3>
      </div>

      <div className="p-2">
        {players.map((player) => (
          <div 
            key={player.rank} 
            className="flex items-center justify-between p-2 hover:bg-white/5 rounded transition-colors group"
          >
            <div className="flex items-center gap-3 min-w-0">
              <span className="text-white/80 font-bold w-4 text-sm">
                {player.rank}
              </span>
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <span className="text-white text-sm truncate" title={player.name}>
                    {truncateText(player.name, 12)}
                  </span>
                </div>
                <span className={`text-xs ${getClassColor(player.className)}`}>
                  {player.className}
                </span>
              </div>
            </div>

            <div className="text-right text-xs shrink-0">
              <span className="text-green-400">{player.kills}</span>
              <span className="text-gray-600 mx-1">/</span>
              <span className="text-red-400">{player.deaths}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const getClassColor = (className: string): string => {
  const colors: Record<string, string> = {
    'Warrior': 'text-orange-400',
    'Mage': 'text-blue-400',
    'Rogue': 'text-purple-400',
    'Archer': 'text-green-400',
    'Paladin': 'text-yellow-400'
  };
  return colors[className] || 'text-gray-400';
};