// src/components/friends/FriendSystem.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Users, UserX, MessageSquare } from 'lucide-react';
import { useClickOutside } from '@/hooks/useClickOutside';
import { UserAvatar } from '@/components/layout/UserMenu';
import { useToast } from '@/components/ui/Toast';
import { useDirectMessaging } from '@/components/messages/DirectMessaging';

interface Friend {
  username: string;
  avatar: string | null;
  status: 'online' | 'away' | 'offline';
  last_activity: string;
}

export const FriendRequestButton: React.FC<{ username: string }> = ({ username }) => {
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const sendFriendRequest = async () => {
    if (isLoading || isSent) return;
    
    setIsLoading(true);
    try {
      const response = await fetch('https://lumerra.online/api/friends/send-request.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });

      const data = await response.json();
      if (data.success) {
        setIsSent(true);
        showToast('Friend request sent!', 'success');
      } else {
        throw new Error(data.message || 'Failed to send friend request');
      }
    } catch (error) {
      showToast(
        error instanceof Error ? error.message : 'Failed to send friend request',
        'error'
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={sendFriendRequest}
      disabled={isLoading || isSent}
      className="flex-1 flex items-center justify-center gap-2 px-4 py-2
        bg-blue-500/10 text-blue-400 rounded-lg text-sm font-medium
        hover:bg-blue-500/20 transition-colors disabled:opacity-50"
    >
      <Users className="w-4 h-4" />
      {isSent ? 'Request Sent' : 'Add Friend'}
    </button>
  );
};

const FriendList: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [friends, setFriends] = useState<Friend[]>([]);
  const { showToast } = useToast();
  const menuRef = useRef<HTMLDivElement>(null);
  const { openChat } = useDirectMessaging();

  useClickOutside(menuRef, () => setIsOpen(false));

  const fetchFriends = async () => {
    try {
      const response = await fetch('https://lumerra.online/api/friends/get-friends.php', {
        credentials: 'include'
      });
      
      const data = await response.json();
      if (data.success) {
        setFriends(data.data.friends);
      }
    } catch (error) {
      console.error('Failed to fetch friends:', error);
    }
  };

  const handleRemoveFriend = async (username: string) => {
    try {
      const response = await fetch('https://lumerra.online/api/friends/remove-friend.php', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username })
      });

      const data = await response.json();
      
      if (data.success) {
        showToast('Friend removed successfully', 'success');
        setFriends(prev => prev.filter(friend => friend.username !== username));
      } else {
        throw new Error(data.message || 'Failed to remove friend');
      }
    } catch (error) {
      showToast(
        error instanceof Error ? error.message : 'Failed to remove friend',
        'error'
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchFriends();
    }
  }, [isOpen]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOpen) {
        fetchFriends();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isOpen]);

  // Add new messages check
  useEffect(() => {
    const checkNewMessages = async () => {
      try {
        const response = await fetch('/api/messages/check-new-messages.php', {
          credentials: 'include'
        });
        
        const data = await response.json();
        if (data.success && data.data.senders.length > 0) {
          data.data.senders.forEach((sender: { username: string; avatar: string | null }) => {
            openChat(sender.username, sender.avatar);
          });
        }
      } catch (error) {
        console.error('Failed to check new messages:', error);
      }
    };

    const interval = setInterval(checkNewMessages, 5000);
    checkNewMessages(); // Initial check
    return () => clearInterval(interval);
  }, [openChat]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'online':
        return 'bg-green-500';
      case 'away':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  const formatLastActivity = (lastActivity: string, status: string): string => {
    if (status === 'online') return 'Online';
    if (status === 'away') return 'Away';
    
    const date = new Date(lastActivity);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    
    if (diff < 60000) return 'Just now';
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return date.toLocaleDateString();
  };

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 text-gray-400 hover:text-white transition-colors relative"
      >
        <Users className="h-5 w-5" />
        {friends.some(f => f.status === 'online') && (
          <div className="absolute -top-1 -right-1 w-2 h-2 bg-green-500 rounded-full animate-pulse" />
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-72 bg-gray-900 rounded-lg shadow-xl 
          border border-white/10 z-50">
          <div className="p-4 border-b border-white/10">
            <h4 className="text-sm font-medium text-white">Friends</h4>
          </div>

          <div className="max-h-[400px] overflow-y-auto">
            {friends.length > 0 ? (
              <div className="p-2">
                {friends.map((friend) => (
                  <div 
                    key={friend.username}
                    className="flex items-center justify-between p-2 hover:bg-white/5 
                      rounded-lg transition-colors group"
                  >
                    <div className="flex items-center gap-3">
                      <div className="relative">
                        <UserAvatar
                          src={friend.avatar}
                          username={friend.username}
                          size="sm"
                        />
                        <div className={`absolute -bottom-0.5 -right-0.5 w-2.5 h-2.5 rounded-full
                          border-2 border-gray-900 ${getStatusColor(friend.status)}`} 
                        />
                      </div>
                      <div>
                        <div className="text-sm text-white font-medium">
                          {friend.username}
                        </div>
                        <div className="text-xs text-gray-400">
                          {formatLastActivity(friend.last_activity, friend.status)}
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-1">
                      <button
                        onClick={() => openChat(friend.username, friend.avatar)}
                        className="p-1.5 text-blue-400 hover:bg-blue-400/10 rounded-lg opacity-0 
                          group-hover:opacity-100 transition-all"
                        title="Send message"
                      >
                        <MessageSquare className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleRemoveFriend(friend.username)}
                        className="p-1.5 text-red-400 hover:bg-red-400/10 rounded-lg opacity-0 
                          group-hover:opacity-100 transition-all"
                        title="Remove friend"
                      >
                        <UserX className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-8 text-center">
                <Users className="w-12 h-12 text-gray-600 mx-auto mb-3" />
                <p className="text-gray-400 text-sm">No friends added yet</p>
              </div>
            )}
          </div>

          <div className="p-3 border-t border-white/5">
            <div className="text-xs text-gray-400 text-center">
              {friends.length} {friends.length === 1 ? 'friend' : 'friends'}
              {friends.filter(f => f.status === 'online').length > 0 && ` • ${
                friends.filter(f => f.status === 'online').length
              } online`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendList;
export type { Friend };