// src/hooks/useClickOutside.ts
import { useEffect, RefObject } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: () => void,
  exceptRef?: RefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current) return;
      
      const target = event.target as Node;
      
      // Check if click is outside the main ref
      const isOutside = !ref.current.contains(target);
      
      // Check if click is not on excepted element
      const isNotExcepted = exceptRef ? !exceptRef.current?.contains(target) : true;
      
      if (isOutside && isNotExcepted) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClick);
    
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, handler, exceptRef]);
};