import React from 'react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { MessageSquare, Calendar, Shield, MapPin } from 'lucide-react';
import { UserAvatar } from '@/components/layout/UserMenu';
import { FriendRequestButton } from '@/components/friends/FriendSystem';
import { useAuth } from '@/hooks/useAuth';
import { formatDate } from '@/utils/formatters';

interface UserProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: {
    username: string;
    avatar?: string | null;
    banner?: string | null;
    bio?: string | null;
    creation_date?: string;
    rank?: string;
    location?: string;
    clan?: string;
  } | null;
}

export const UserProfileModal: React.FC<UserProfileModalProps> = ({
  isOpen,
  onClose,
  userData
}) => {
  const { user } = useAuth();

  if (!userData) return null;

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[425px] p-0 bg-gray-900 border-gray-800 overflow-hidden">
        {/* Banner */}
        <div className="relative h-32 w-full bg-gradient-to-br from-blue-500/20 to-purple-500/20">
          {userData.banner ? (
            <img
              src={`https://lumerra.online${userData.banner}`}
              alt={`${userData.username}'s banner`}
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 via-purple-600/20 to-blue-600/20" />
          )}
        </div>

        {/* Profile Content */}
        <div className="px-6 pb-6">
          {/* Avatar */}
          <div className="flex justify-between -mt-12">
            <div className="relative">
              <div className="ring-4 ring-gray-900 rounded-full">
                <UserAvatar
                  src={userData.avatar}
                  username={userData.username}
                  size="lg"
                />
              </div>
            </div>
          </div>

          {/* User Info */}
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-bold text-white">
                {userData.username}
              </h2>
              {userData.rank && (
                <span className="px-2 py-1 text-xs font-medium bg-blue-500/10 
                  text-blue-400 rounded-full">
                  {userData.rank}
                </span>
              )}
            </div>

            {/* Stats Row */}
            <div className="flex items-center gap-4 mt-2 text-sm text-gray-400">
              {userData.creation_date && (
                <div className="flex items-center gap-1">
                  <Calendar className="w-4 h-4" />
                  <span>Joined {formatDate(userData.creation_date)}</span>
                </div>
              )}
            </div>

            {/* Additional Info */}
            <div className="mt-4 space-y-3">
              {userData.clan && (
                <div className="flex items-center gap-2 text-sm">
                  <Shield className="w-4 h-4 text-blue-400" />
                  <span className="text-gray-300">{userData.clan}</span>
                </div>
              )}

              {userData.location && (
                <div className="flex items-center gap-2 text-sm">
                  <MapPin className="w-4 h-4 text-green-400" />
                  <span className="text-gray-300">{userData.location}</span>
                </div>
              )}
            </div>

            {/* Bio */}
            {userData.bio && (
              <div className="mt-4 p-4 rounded-lg bg-black/20 border border-white/5">
                <p className="text-sm text-gray-300 whitespace-pre-wrap">
                  {userData.bio}
                </p>
              </div>
            )}

            {/* Action Buttons */}
            <div className="mt-6 flex gap-3">
              <button
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 
                  bg-blue-500 hover:bg-blue-600 text-white rounded-lg 
                  text-sm font-medium transition-colors"
              >
                <MessageSquare className="w-4 h-4" />
                Send Message
              </button>

              {/* Only show friend request button if looking at another user's profile */}
              {user && user.username !== userData.username && (
                <FriendRequestButton username={userData.username} />
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserProfileModal;