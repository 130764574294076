import React from 'react';
import { Clock, Trophy } from 'lucide-react';
import type { TimeOnlinePlayer } from '../../types/stats';
import { truncateText } from '../../utils/formatters';

interface TimeOnlineRankingsProps {
  players: TimeOnlinePlayer[];
}

export const TimeOnlineRankings: React.FC<TimeOnlineRankingsProps> = ({ players }) => {
  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Clock className="text-yellow-400 h-4 w-4" />
          Most Time Online
        </h3>
      </div>

      <div className="p-2">
        {players.map((player) => (
          <div 
            key={player.rank} 
            className="flex items-center justify-between p-2 hover:bg-white/5 rounded transition-colors group"
          >
            <div className="flex items-center gap-3 min-w-0">
              {player.rank === 1 && (
                <Trophy className="h-4 w-4 text-yellow-400 absolute -ml-6 opacity-0 group-hover:opacity-100 transition-opacity" />
              )}
              <span className="text-white/80 font-bold w-4 text-sm">
                {player.rank}
              </span>
              <div className="min-w-0">
                <div className="flex items-center gap-2">
                  <span className="text-white text-sm truncate" title={player.name}>
                    {truncateText(player.name, 12)}
                  </span>
                </div>
                <span className="text-gray-400 text-xs">
                  Level {player.level}
                </span>
              </div>
            </div>
            
            <div className="text-yellow-300 text-xs font-medium shrink-0">
              {player.hours}h
            </div>
          </div>
        ))}
      </div>

      <div className="p-3 border-t border-white/5">
        <div className="text-center text-xs text-gray-400">
          Updated hourly
        </div>
      </div>
    </div>
  );
};