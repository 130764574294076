import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { UserAvatar } from '@/components/layout/UserMenu';
import type { ChatProps } from './LiveChat';

export const MobileChat: React.FC<ChatProps> = ({
  messages,
  onSendMessage,
  isLoading
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (inputValue.trim()) {
      onSendMessage(inputValue.trim());
      setInputValue('');
    }
  };

  return (
    <div className="fixed inset-x-0 bottom-0 bg-gray-900 border-t border-white/10">
      <div className="h-48 overflow-y-auto p-4 space-y-4">
        {messages.map((msg, index) => (
          <div key={`${msg.id}-${index}`} className="flex items-start gap-3">
            <UserAvatar src={msg.avatar} username={msg.sender} size="sm" />
            <div>
              <span className="text-blue-400 text-sm font-medium">{msg.sender}</span>
              <p className="text-white text-sm">{msg.content}</p>
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="p-3 border-t border-white/10">
        <div className="flex gap-2">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type a message..."
            className="flex-grow bg-black/20 rounded-lg px-3 py-2 text-white 
              text-sm border border-white/10 focus:outline-none focus:border-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !inputValue.trim()}
            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 
              transition-colors disabled:opacity-50"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
      </form>
    </div>
  );
};