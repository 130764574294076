// src/hooks/useAuth.ts
import { useContext } from 'react';
import { AuthContext } from '@/context/AuthContext';
import type { User } from '@/types/auth';

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function assertAuthenticated(user: User | null): asserts user is User {
  if (!user) {
    throw new Error('User must be authenticated');
  }
}

export type { User, AuthContextType } from '@/types/auth';