import React, { useEffect, useState } from 'react';
import { X } from 'lucide-react';

interface MobileMenuProps {
  isOpen: boolean;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  setIsOpen: (isOpen: boolean) => void;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  activeTab,
  setActiveTab,
  setIsOpen
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
      setTimeout(() => setIsVisible(false), 300);
    }
  }, [isOpen]);

  if (!isVisible) return null;

  const navItems = [
    { id: 'news', label: 'NEWS' },
    { id: 'about', label: 'ABOUT' },
    { id: 'register', label: 'REGISTER' },
    { id: 'files', label: 'FILES' },
    { id: 'donate', label: 'DONATE' },
    { id: 'forum', label: 'FORUM' }
  ];

  return (
    <div 
      className={`fixed inset-0 z-50 transition-opacity duration-300
        ${isAnimating ? 'opacity-100' : 'opacity-0'}`}
    >
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/80 backdrop-blur-sm"
        onClick={() => setIsOpen(false)}
      />

      {/* Menu Content */}
      <div 
        className={`absolute right-0 top-0 bottom-0 w-64 bg-gray-900 
          transform transition-transform duration-300 ease-out
          ${isAnimating ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className="flex justify-between items-center p-4 border-b border-white/10">
          <h2 className="text-lg font-bold text-white">Menu</h2>
          <button
            onClick={() => setIsOpen(false)}
            className="p-2 text-gray-400 hover:text-white transition-colors"
            aria-label="Close menu"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="py-4">
          {navItems.map((item) => (
            <button
              key={item.id}
              onClick={() => {
                setActiveTab(item.id);
                setIsOpen(false);
              }}
              className={`w-full text-left px-6 py-3 text-sm transition-colors
                ${activeTab === item.id 
                  ? 'text-blue-400 bg-blue-500/10' 
                  : 'text-gray-400 hover:text-white hover:bg-white/5'
                }`}
            >
              {item.label}
            </button>
          ))}

          <div className="px-4 pt-4 mt-4 border-t border-white/10">
            <button className="w-full py-2 bg-blue-500 text-white rounded 
              hover:bg-blue-600 transition-colors text-sm font-medium">
              PLAY NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};