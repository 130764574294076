// src/components/messages/DirectMessaging.tsx
import React, { useState, createContext, useContext, useEffect } from 'react';
import { Send, X, Maximize2, Minimize2 } from 'lucide-react';
import { UserAvatar } from '@/components/layout/UserMenu';
import { useToast } from '@/components/ui/Toast';

interface Message {
  id: number;
  sender_username: string;
  receiver_username: string;
  content: string;
  sender_avatar: string | null;
  created_at: string;
  is_read: boolean;
}

interface DirectChatWindowProps {
  username: string;
  avatar: string | null;
  onClose: () => void;
}

const DirectChatWindow: React.FC<DirectChatWindowProps> = ({
  username,
  avatar,
  onClose
}) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();

  const fetchMessages = async () => {
    try {
      const response = await fetch(
        `https://lumerra.online/api/messages/get-private-messages.php?username=${encodeURIComponent(username)}`,
        {
          credentials: 'include'
        }
      );

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      if (data.success) {
        setMessages(data.data.messages);
      }
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
  e.preventDefault();
  if (!inputValue.trim() || isLoading) return;

  setIsLoading(true);
  const messageContent = inputValue.trim();
  
  try {
    const response = await fetch('https://lumerra.online/api/messages/send-private-message.php', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        receiver: username,
        content: messageContent
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    
    if (data.success) {
      // Add the new message immediately
      const newMessage = {
        id: data.data.message_id,
        sender_username: data.data.sender,
        receiver_username: username,
        content: data.data.content,
        sender_avatar: data.data.sender_avatar,
        created_at: data.data.timestamp,
        is_read: data.data.is_read
      };
      
      setMessages(prev => [...prev, newMessage]);
      setInputValue('');
    } else {
      throw new Error(data.message || 'Failed to send message');
    }
  } catch (error) {
    console.error('Send message error:', error);
    showToast(error instanceof Error ? error.message : 'Failed to send message', 'error');
  } finally {
    setIsLoading(false);
  }
};

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 3000);
    return () => clearInterval(interval);
  }, [username]);

  if (isMinimized) {
    return (
      <div className="bg-gray-900 rounded-t-lg border border-white/10 w-72 flex items-center justify-between p-3">
        <div className="flex items-center gap-2">
          <UserAvatar src={avatar} username={username} size="sm" />
          <span className="text-white text-sm font-medium">{username}</span>
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => setIsMinimized(false)}
            className="p-1 text-gray-400 hover:text-white transition-colors"
          >
            <Maximize2 className="w-4 h-4" />
          </button>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-900 rounded-t-lg border border-white/10 w-72">
      <div className="flex items-center justify-between p-3 border-b border-white/10">
        <div className="flex items-center gap-2">
          <UserAvatar src={avatar} username={username} size="sm" />
          <span className="text-white text-sm font-medium">{username}</span>
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={() => setIsMinimized(true)}
            className="p-1 text-gray-400 hover:text-white transition-colors"
          >
            <Minimize2 className="w-4 h-4" />
          </button>
          <button
            onClick={onClose}
            className="p-1 text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="h-80 overflow-y-auto p-4 space-y-4 flex flex-col-reverse">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${
              message.sender_username === username ? 'justify-start' : 'justify-end'
            } gap-2`}
          >
            {message.sender_username === username && (
              <UserAvatar
                src={message.sender_avatar}
                username={message.sender_username}
                size="sm"
              />
            )}
            <div
              className={`max-w-[70%] px-3 py-2 rounded-lg ${
                message.sender_username === username
                  ? 'bg-gray-700 text-white'
                  : 'bg-blue-500 text-white'
              }`}
            >
              <p className="text-sm break-words">{message.content}</p>
              <span className="text-xs opacity-75">
                {new Date(message.created_at).toLocaleTimeString()}
              </span>
            </div>
            {message.sender_username !== username && (
              <UserAvatar
                src={message.sender_avatar}
                username={message.sender_username}
                size="sm"
              />
            )}
          </div>
        ))}
      </div>

      <form onSubmit={handleSendMessage} className="p-3 border-t border-white/10">
        <div className="flex gap-2">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type a message..."
            className="flex-grow bg-black/20 rounded-lg px-3 py-2 text-white 
              text-sm border border-white/10 focus:outline-none focus:border-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || !inputValue.trim()}
            className="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 
              transition-colors disabled:opacity-50"
          >
            <Send className="w-4 h-4" />
          </button>
        </div>
      </form>
    </div>
  );
};

interface ActiveChat {
  username: string;
  avatar: string | null;
}

interface DirectMessagingContextType {
  openChat: (username: string, avatar: string | null) => void;
  closeChat: (username: string) => void;
  activeChats: ActiveChat[];
}

const DirectMessagingContext = createContext<DirectMessagingContextType | undefined>(undefined);

export const DirectMessagingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeChats, setActiveChats] = useState<ActiveChat[]>([]);

  const openChat = (username: string, avatar: string | null) => {
    setActiveChats(prev => {
      if (prev.some(chat => chat.username === username)) {
        return prev;
      }
      return [...prev, { username, avatar }];
    });
  };

  const closeChat = (username: string) => {
    setActiveChats(prev => prev.filter(chat => chat.username !== username));
  };

  useEffect(() => {
    const checkNewMessages = async () => {
      try {
        const response = await fetch('https://lumerra.online/api/messages/check-new-messages.php', {
          credentials: 'include'
        });
        
        const data = await response.json();
        if (data.success && data.data.senders.length > 0) {
          data.data.senders.forEach((sender: { username: string; avatar: string | null }) => {
            openChat(sender.username, sender.avatar);
          });
        }
      } catch (error) {
        console.error('Failed to check new messages:', error);
      }
    };

    const interval = setInterval(checkNewMessages, 5000);
    checkNewMessages(); // Initial check
    return () => clearInterval(interval);
  }, []);

  return (
    <DirectMessagingContext.Provider value={{ activeChats, openChat, closeChat }}>
      {children}
      <div className="fixed bottom-0 right-[340px] flex gap-4 z-50">
        {activeChats.map(chat => (
          <DirectChatWindow
            key={chat.username}
            username={chat.username}
            avatar={chat.avatar}
            onClose={() => closeChat(chat.username)}
          />
        ))}
      </div>
    </DirectMessagingContext.Provider>
  );
};

export const useDirectMessaging = () => {
  const context = useContext(DirectMessagingContext);
  if (!context) {
    throw new Error('useDirectMessaging must be used within a DirectMessagingProvider');
  }
  return context;
};