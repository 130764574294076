import React from 'react';
import { Shield, Trophy, Skull } from 'lucide-react';
import type { PvEPlayer } from '../../types/stats';
import { truncateText, formatNumber } from '../../utils/formatters';

interface PvERankingsProps {
  players: PvEPlayer[];
}

export const PvERankings: React.FC<PvERankingsProps> = ({ players }) => {
  return (
    <div className="bg-black/20 backdrop-blur-sm rounded-lg border border-white/10">
      <div className="p-4 border-b border-white/10">
        <h3 className="text-lg font-bold text-white flex items-center gap-2">
          <Shield className="text-green-400 h-4 w-4" />
          PVE Rankings
        </h3>
      </div>

      <div className="p-2">
        {players.map((player) => (
          <div 
            key={player.rank} 
            className="relative"
          >
            <div className="flex items-center justify-between p-2 hover:bg-white/5 rounded 
              transition-colors group cursor-pointer"
            >
              <div className="flex items-center gap-3 min-w-0">
                {player.rank <= 3 && (
                  <Trophy className={`h-4 w-4 absolute -ml-6 
                    ${player.rank === 1 ? 'text-yellow-400' : 
                      player.rank === 2 ? 'text-gray-400' : 'text-amber-700'}
                    opacity-0 group-hover:opacity-100 transition-opacity`}
                  />
                )}
                <span className="text-white/80 font-bold w-4 text-sm">
                  {player.rank}
                </span>
                <div className="min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="text-white text-sm truncate" title={player.name}>
                      {truncateText(player.name, 12)}
                    </span>
                    {player.guildName && (
                      <span className="text-xs text-gray-400">
                        &lt;{truncateText(player.guildName, 8)}&gt;
                      </span>
                    )}
                  </div>
                  <span className={`text-xs ${getClassColor(player.className)}`}>
                    {player.className}
                  </span>
                </div>
              </div>
              
              <div className="text-right shrink-0">
                <div className="text-green-400 text-sm font-bold">
                  {formatNumber(player.score)}
                </div>
              </div>
            </div>

            {/* Detailed Stats Popup */}
            <div className="absolute left-full ml-2 top-0 z-10 w-52 bg-black/90 rounded-lg 
              border border-white/10 p-3 pointer-events-none opacity-0 translate-x-2
              group-hover:opacity-100 group-hover:translate-x-0 transition-all duration-200
              hidden lg:block"
            >
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Skull className="h-3 w-3 text-red-400" />
                  <span className="text-xs text-gray-400">Bosses Defeated:</span>
                  <span className="text-xs text-white ml-auto">
                    {player.bossesDefeated}
                  </span>
                </div>
                <div>
                  <div className="text-xs text-gray-400 mb-1">Highest Dungeon:</div>
                  <div className="text-xs text-white bg-white/5 rounded px-2 py-1">
                    {player.highestDungeon}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="p-3 border-t border-white/5 flex items-center justify-between">
        <div className="text-xs text-gray-400">
          Season 2 Rankings
        </div>
        <div className="text-xs text-gray-400">
          Updated hourly
        </div>
      </div>
    </div>
  );
};

const getClassColor = (className: string): string => {
  const colors: Record<string, string> = {
    'Tank': 'text-blue-400',
    'DPS': 'text-red-400',
    'Healer': 'text-green-400',
    'Support': 'text-purple-400'
  };
  return colors[className] || 'text-gray-400';
};